import { classNames } from '@/utils/class-names';

type CardProps = {
  heading?: string;
  className?: string;
  children?: React.ReactNode;
};

export const cardClassNames = {
  wrapper: 'mb-6 rounded-lg border-gray-200 bg-white py-6 px-4 shadow-sm sm:px-6',
  heading: 'text-xl font-bold tracking-tight text-gray-900',
};

export const Card = ({ heading, className, children }: CardProps) => {
  const wrapperClassName = classNames(cardClassNames.wrapper, className);
  return (
    <div className={wrapperClassName}>
      {heading && <h2 className={cardClassNames.heading}>{heading}</h2>}
      {children}
    </div>
  );
};

export const StickyHeaderCard = ({
  header,
  className,
  children,
}: CardProps & { header: React.ReactNode }) => {
  const wrapperClassName = classNames(cardClassNames.wrapper, 'pt-0', className);
  return (
    <div className={wrapperClassName}>
      <div className="sticky top-0 z-10 mb-6 flex items-center border-b bg-white py-4">
        {header}
      </div>
      {children}
    </div>
  );
};
