import { ButtonVariants, buttonClassNames, classNames } from '@/utils/class-names';
import React, { ButtonHTMLAttributes, forwardRef } from 'react';

type ButtonProps = {
  variant: ButtonVariants;
  children: React.ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  visiblyDisabled?: boolean;
  className?: string;
  onClick?: (event: React.SyntheticEvent) => void;
  onVisiblyDisabledClick?: (event: React.SyntheticEvent) => void;
  title?: string;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      type = 'button',
      disabled = false,
      visiblyDisabled = false,
      children,
      className,
      onClick,
      onVisiblyDisabledClick,
      title,
    },
    ref
  ) => {
    const allClassNames = classNames(
      buttonClassNames[variant],
      disabled || visiblyDisabled ? 'opacity-75 cursor-not-allowed' : '',
      className
    );

    const handleClick = (event: React.SyntheticEvent) => {
      // Should never happen
      if (disabled) return;

      if (visiblyDisabled) {
        // When visibly disabled button is clicked, tell user why they can't click this button
        if (onVisiblyDisabledClick) onVisiblyDisabledClick(event);
        return;
      }

      // Normal click
      if (onClick) onClick(event);
    };

    return (
      <button
        className={allClassNames}
        type={type}
        disabled={disabled}
        onClick={handleClick}
        ref={ref}
        title={title}
      >
        {children}
      </button>
    );
  }
);
